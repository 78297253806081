import React, { useEffect } from "react"
import { MdAccountCircle } from "react-icons/md"
import { navigate } from "gatsby"
import * as Sentry from "@sentry/gatsby"
import { useAppState } from "../../components/provider"
import { statuses as authStatuses } from "../../hooks/auth"
import Layout from "../../components/layout"
import LoaderBlock from "../../components/loader"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Link } from "gatsby"

export const veriffStatuses = {
  started: "started",
  submitted: "submitted",
  declined: "declined",
  resubmission: "resubmission",
  approved: "approved",
}

const IdentityPanel = ({ profile }) => {
  const { buyback = {} } = profile
  const { veriffStatus } = buyback

  useEffect(() => {
    if (veriffStatus && ["started", "resubmission"].indexOf(veriffStatus) < 0) {
      return
    }

    if (typeof window !== "undefined" && window.Veriff) {
      const veriff = window.Veriff({
        host: "https://stationapi.veriff.com",
        apiKey: process.env.GATSBY_VERIFF_API_KEY,
        parentId: "veriff-root",
        onSession(err, response) {
          window.veriffSDK.createVeriffFrame({ url: response.verification.url })
        },
      })

      veriff.setParams({
        vendorData: profile.uid,
      })

      veriff.mount({
        formLabel: {
          vendorData: "UID",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Veriff, veriffStatus])

  switch (veriffStatus) {
    case veriffStatuses.approved:
      return (
        <section className="Text centered">
          <MdAccountCircle size="7em" className="Icon success" />
          <p>You identity is verified.</p>
          <Link className="Btn primary lg" to="/buyback/refund-address">
            Continue
          </Link>
        </section>
      )
    case veriffStatuses.declined:
      return (
        <section className="Text centered">
          <MdAccountCircle size="7em" className="Icon failed" />
          <p className="Text">
            Your verification got rejected by the verification system.
          </p>
          <p className="Text">
            We're investigating the case and will get back to you. In meanwhile
            you can contact us on Intercom or write us an email to{" "}
            <a href="mailto:hello@mothership.cx">hello@mothership.cx</a>
          </p>
          <Link className="Btn primary lg" to="/profile">
            Return to your profile
          </Link>
        </section>
      )
    case veriffStatuses.submitted:
      return (
        <section className="Text centered">
          <div className="Progress">
            <CircularProgress size="5rem" color="inherit" />
          </div>
          <p className="Text">
            The identification platform is processing your data. Usually it
            takes up to 5 minutes.
          </p>
          <p className="Text">
            We'll keep you posted once we got a result. If you didn't receive
            the result in an hour, please contact us on Intercom.
          </p>
          <Link className="Btn primary lg" to="/profile">
            Return to your profile
          </Link>
        </section>
      )
    default:
      return (
        <>
          <div id="veriff-root"></div>
        </>
      )
  }
}

const Identity = () => {
  const {
    auth: { status: authStatus, user, profile = {} },
  } = useAppState()

  if (user === null && authStatus === authStatuses.initial) {
    navigate("/")
    return null
  }
  const { buyback = {} } = profile
  if (!buyback.isAvailable && typeof window !== "undefined") {
    navigate("/profile")
    return null
  }

  return (
    <Layout>
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          scope.setUser({ id: profile.uid })
        }}
      >
        <div className="Section">
          <div className="PanelContainer">
            <h1 className="Title">Identity Verification</h1>
            <section className="Panel centered">
              {profile.uid ? (
                <IdentityPanel profile={profile} />
              ) : (
                <LoaderBlock isLoading />
              )}
            </section>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Layout>
  )
}

export default Identity
